import React, { useEffect } from "react"
import Modal from "react-modal"
import logo from "../../images/patamedslogo.svg"
import styled from "styled-components"
import { Link } from "gatsby"
import { Row } from "react-bootstrap"

const customStyles = {
  content: {
    top: "25%",
    bottom: "auto",
  },
  overlay: {
    zIndex: "5000",
  },
}

export default function ThankYouModal({
  thankYouModal,
  openThankYouModal,
  closeThankYouModal,
}) {
  const isBrowser = typeof window !== "undefined"
  const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    closeThankYouModal()
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isLoggedIn && thankYouModal == true) {
      if (typeof window !== "undefined") {
        openModal()
      }
    }
  }, [])

  const [user] = React.useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <Container>
        <HeroText>
          <LinkLogo>
            <img src={logo} alt="logo" />
          </LinkLogo>
          <Row>
            <div>
              <h3>Thank You</h3>
              <p>
                Email verification link has been sent to {user.email}. Please
                verify your email to activate your account.
              </p>
            </div>
          </Row>
        </HeroText>
        <div>
          <Button onClick={() => closeModal()}>Close</Button>
        </div>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    #1c333a 10%,
    #1b353c 50%,
    #1d3238 70%,
    #187e9c 100%
  );
  //height: 50vh;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  z-index: 4000;
  margin: -1.1rem -1.1rem;
`
const HeroText = styled.div`
  color: #fff;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
`
const LinkLogo = styled.div`
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;

  @media screen and (min-width: 600px) {
    width: 40%;
  }
  @media screen and (min-width: 800px) {
    width: 30%;
  }
  @media screen and (min-width: 1100px) {
    width: 20%;
  }
  @media screen and (min-width: 1500px) {
    width: 10%;
  }
`
const Button = styled.div`
  cursor: pointer;
  background: #ff5722;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  color: #fff;
  margin-bottom: 1rem;

  :hover {
    background: #f44336;
  }

  @media screen and (min-width: 600px) {
    padding: 1rem 5rem;
  }
`
