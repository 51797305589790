import React, { useState } from "react"
import styled from "styled-components"
import algoliasearch from "algoliasearch/lite"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { Hits } from "../DisplayHits"
import {
  connectHits,
  InstantSearch,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom"
import SearchBox from "../SearchBox"
import { globalHistory } from "@reach/router"
import ThankYouModal from "./ThankYouModal"
import ThankYouVerificationModal from "./ThankYouVerificationModal"
import ProgressiveProfillingForm from "./ProgressiveProfillingForm"
import GoogleRegistrationSetup from "./GoogleRegistrationSetup"
import EmailVerificationModal from "./EmailVerificationModal"
import { Container, Row, Col, Button, Nav } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import { AiOutlineRight } from "react-icons/ai"
import { AiOutlineCaretRight } from "react-icons/ai"
import { BsWhatsapp } from "react-icons/bs"
import { NavbarContext } from "../../context/navbar"
import { useQueryParam, StringParam } from "use-query-params"

const Body = ({
  thankYouModal,
  openThankYouModal,
  closeThankYouModal,
  thankYouVerificationModal,
  setThankYouVerificationModal,
  navbar,
  toggle,
  scroll,
  scrollTo,
  aboutRef,
  topRef,
  servicesRef,
  footerRef,
  tutorialRef,
  profileRef,
  resourcesRef,
  blogRef,
  isOpenVerifyLoginAlert,
}) => {
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_ADMIN_KEY
  )
  const CustomSearchBox = connectSearchBox(SearchBox)
  const CustomHits = connectHits(Hits)

  let urlQuery = globalHistory.location.search
    ? globalHistory.location.search.replace("?query=", "")
    : ""

  const [token] = useQueryParam("token", StringParam)

  if (token) {
    urlQuery = ""
  }

  const Results = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
      <div>
        <CustomHits />
      </div>
    ) : (
      <div></div>
    )
  )

  const isBrowser = typeof window !== "undefined"

  const [isMobile, setIsMobile] = useState(
    isBrowser && window.innerWidth <= 768 ? true : false
  )

  const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )

  const executeScroll = ref => {
    if (ref.current) {
      toggle()
      ref.current.scrollIntoView()
    }
  }

  const mediaQuery = "(max-width: 768px)"

  if (isBrowser) {
    let mediaQueryList = window.matchMedia(mediaQuery)
    mediaQueryList.addEventListener("change", event => {
      if (event.matches) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const [
    isOpenCompleteRegistrationModal,
    setIsOpenCompleteRegistrationModal,
  ] = useState(false)

  const closeCompleteRegistrationModal = () => {
    setIsOpenCompleteRegistrationModal(false)
  }

  React.useEffect(() => {
    const isLoggedIn = Object.keys(user).length !== 0
    if (isLoggedIn) {
      !!user?.profession == false && setIsOpenCompleteRegistrationModal(true)
    }
  }, [])

  React.useEffect(() => {
    scrollTo.current.scrollIntoView()
  }, [scrollTo])

  return (
    <React.Fragment>
      <div ref={topRef}>
        {/*   <Modal /> */}
        {/* <ProgressiveProfillingForm /> */}
        <ThankYouModal
          thankYouModal={thankYouModal}
          openThankYouModal={openThankYouModal}
          closeThankYouModal={closeThankYouModal}
        />

        <EmailVerificationModal />

        <ThankYouVerificationModal
          thankYouVerificationModal={thankYouVerificationModal}
          setThankYouVerificationModal={setThankYouVerificationModal}
        />

        <GoogleRegistrationSetup
          isOpenCompleteRegistrationModal={isOpenCompleteRegistrationModal}
          closeCompleteRegistrationModal={closeCompleteRegistrationModal}
        />

        <div>
          {navbar == true && isMobile == true && (
            <div
              style={{
                backgroundColor: "black",
                position: "fixed",
                zIndex: 1001,
                width: "100%",
              }}
            >
              <Nav
                style={{
                  flexDirection: "column",
                  margin: "10px",
                }}
              >
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    executeScroll(aboutRef)
                  }}
                >
                  About
                </div>
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    executeScroll(resourcesRef)
                  }}
                >
                  Resources
                </div>
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    executeScroll(servicesRef)
                  }}
                >
                  Services
                </div>
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    executeScroll(blogRef)
                  }}
                >
                  Blog
                </div>
                <div
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    executeScroll(footerRef)
                  }}
                >
                  Contact
                </div>
              </Nav>
            </div>
          )}
        </div>

        <Container
          fluid
          style={{
            backgroundColor: "#fff",
            color: "#000",
            minHeight: "100%",
          }}
        >
          <Row
            style={{
              minHeight: "100v",
              paddingTop: `${isMobile ? "30px" : "50px"}`,
              display: "flex",
              flexWrap: "wrap-reverse",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={12} sm={6} style={{ margin: "auto" }}>
              <div>
                <h1>Save time and money{navbar}</h1>
              </div>
              <div>
                <p>
                  Ordering and Request For Quotation Made Easy. Upload your order list or search medication
                </p>
              </div>
              <div>
                <a href="https://wa.me/+254792640973?text=Hello Patameds, &source=homepage" target="_blank">
                  <Button
                    style={{
                      backgroundColor: "#00A14B",
                      marginBottom: "1rem",
                      maxWidth: "300px",
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "10px"
                    }}
                  >
                    <BsWhatsapp style={{ display: "inline" }} /> Buy on WhatsApp
                  </Button>
                </a>
                <Button
                  onClick={() => {
                    //executeScroll(tutorialRef)
                    navigate('/upload')
                  }}
                  style={{
                    backgroundColor: "#00A14B",
                    marginBottom: "1rem",
                    maxWidth: "300px",
                    border: "1px solid #fff",
                    borderRadius: "10px"
                  }}
                >
                  Upload Order List <AiOutlineCaretRight style={{ display: "inline" }} />
                </Button>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <StaticImage
                src="../../images/canva/43.jpeg"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="online marketplace"
                style={{
                  paddingTop: "20px",
                  marginBottom: "1rem",
                }}
              />
            </Col>
          </Row>
        </Container>

        <Container
          ref={resourcesRef}
          fluid
          style={{
            backgroundColor: "#03989e",
            color: "white",
            minHeight: "100%",
          }}
        >
          <Row
            style={{
              minHeight: "80vh",
              paddingTop: `${isMobile ? "10px" : "50px"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={12} sm={6}>
              <StaticImage
                src="../../images/canva/42.jpeg"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="continues medical education"
                style={{
                  marginBottom: "1rem",
                }}
              />
            </Col>
            <Col xs={12} sm={6} style={{ margin: "auto" }}>
              <div>
                <h2>Resources</h2>
              </div>
              <div>
                <p>
                  These constitute a curated list of continuous medical
                  education, continuous professional education, webinars with
                  and without CPDs points.
                </p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    navigate("/cme")
                  }}
                  style={{ backgroundColor: "#00A14B", maxWidth: "300px", borderRadius: "10px" }}
                >
                  Learn More <AiOutlineCaretRight style={{ display: "inline" }} />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <Container
          ref={aboutRef}
          fluid
          style={{
            minHeight: "100%",
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          <Row
            style={{
              minHeight: "75vh",
              paddingTop: `${isMobile ? "10px" : "50px"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={12} sm={6}>
              <StaticImage
                src="../../images/canva/31.png"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="Company office"
              />
            </Col>
            <Col xs={12} sm={6} style={{ margin: "auto" }}>
              <div>
                <h2>About Us</h2>
              </div>
              <div>
                <p>
                  Patameds is a B2B pharmaceutical marketplace platform that
                  facilitates marketing and distribution of medicines in Kenya.
                </p>
                <p>
                  It connects healthcare providers, retailers, hospitals,
                  clinics, wholesalers, distributors and manufacturers on a
                  single platform to facilitate trade
                </p>
                <p>
                  Discover and connect directly to sellers and interested buyers
                  to discuss trade
                </p>
                <p>
                  Easily buy medication online from verified pharmaceutical companies in Kenya.
                  Discover products at better rates, transact and get them delivered
                </p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    executeScroll(profileRef)
                  }}
                  style={{
                    backgroundColor: "#00A14B",
                    marginBottom: "1rem",
                    maxWidth: "300px",
                    borderRadius: "10px"
                  }}
                >
                  Company Profile <AiOutlineCaretRight style={{ display: "inline" }} />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            minHeight: "80vh",
            backgroundColor: "#03989e",
            color: "white",
          }}
        >
          <div
            style={{
              minHeight: "100v",
              paddingTop: `${isMobile ? "10px" : "50px"}`,
              margin: "0px 20px",
            }}
          >
            <Row>
              <h2>Our Clients</h2>
            </Row>
            <Row>
              <p>
                The following stakeholders have entrusted us with being their
                firm of choice:
              </p>
            </Row>

            <Row>
              <Col
                xs={6}
                sm={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/healthcare_workers")
                }}
              >
                {" "}
                <div style={{ margin: "5px" }}>
                  <Row>
                    <StaticImage
                      src="../../images/canva/32.png"
                      layout="fullWidth"
                      loading="eager"
                      placeholder="tracedSVG"
                      alt="Healthcare Provider"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h6>
                        Healthcare Providers{" "}
                        <AiOutlineCaretRight style={{ display: "inline" }} />
                      </h6>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col
                xs={6}
                sm={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/retail_hospitals")
                }}
              >
                <div style={{ margin: "5px" }}>
                  <Row>
                    <StaticImage
                      src="../../images/canva/33.png"
                      layout="fullWidth"
                      loading="eager"
                      placeholder="tracedSVG"
                      alt="Retail and Hospitals"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h6>
                        Hospitals/Retail Pharmacies{" "}
                        <AiOutlineCaretRight style={{ display: "inline" }} />
                      </h6>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col
                xs={6}
                sm={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/wholesaler")
                }}
              >
                <div style={{ margin: "5px" }}>
                  <Row>
                    <StaticImage
                      src="../../images/canva/34.png"
                      layout="fullWidth"
                      loading="eager"
                      placeholder="tracedSVG"
                      alt="Pharmaceutical Wholesalers"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h6>
                        Pharmaceutical Wholesalers{" "}
                        <AiOutlineCaretRight style={{ display: "inline" }} />
                      </h6>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col
                xs={6}
                sm={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/distributor")
                }}
              >
                <div style={{ margin: "5px" }}>
                  <Row>
                    <StaticImage
                      src="../../images/canva/35.png"
                      layout="fullWidth"
                      loading="eager"
                      placeholder="tracedSVG"
                      alt="Pharmaceutical Distributors"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <h6>
                        Distributors / Manufacturers{" "}
                        <AiOutlineCaretRight style={{ display: "inline" }} />
                      </h6>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Container
          ref={servicesRef}
          fluid
          style={{
            minHeight: "100%",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <div
            css={`
              text-align: center;
              padding-top: 1rem;
            `}
          >
            <h2>Our Services</h2>
          </div>
          <Row
            style={{
              paddingTop: `${isMobile ? "10px" : "50px"}`,
            }}
          >
            <Col xs={12} sm={6}>
              <div>
                <StaticImage
                  src="../../images/canva/36.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Our services"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} style={{ height: "100v" }}>
              <Row style={{ borderTop: "2px solid black" }}>
                <Col xs={4}>
                  <h1>01</h1>
                </Col>

                <Col xs={8} style={{ textAlign: "justify" }}>
                  <h3>Product Discovery</h3>
                  <p>
                    Enables product discovery and online purchase by retail
                    pharmacies, hospitals, wholesalers, and distributors
                  </p>
                </Col>
              </Row>
              <Row style={{ borderTop: "2px solid black" }}>
                <Col xs={4}>
                  <h1>02</h1>
                </Col>

                <Col xs={8} style={{ textAlign: "justify" }}>
                  <h3>Data Analytics</h3>
                  <p>
                    Provides data analytics dashboards to the stakeholders based
                    on the data generated from the e-commerce platform.
                  </p>
                </Col>
              </Row>
              <Row style={{ borderTop: "2px solid black" }}>
                <Col xs={4}>
                  <h1>03</h1>
                </Col>

                <Col xs={8} style={{ textAlign: "justify" }}>
                  <h3>Omnichannel Marketing</h3>
                  <p>
                    Enables pharmaceutical distributors to market their products
                    to healthcare providers within the platform.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container
          ref={blogRef}
          fluid
          style={{
            minHeight: "100%",
            backgroundColor: "#fff",
            color: "#000",
          }}
        >
          <Row
            style={{
              minHeight: "65vh",
              paddingTop: `${isMobile ? "10px" : "50px"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col xs={12} sm={6} style={{ margin: "auto" }}>
              <div>
                <h2>Our Blog</h2>
              </div>
              <div>
                <p>
                  These are Inspirational, Entertaining, News, Promotional,
                  Community development content that address the benefits and
                  how health systems can adopt technology.
                </p>
              </div>
              <div>
                <a
                  href="https://www.tripleaimsoftware.com/blog"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    style={{
                      backgroundColor: "#00A14B",
                      marginBottom: "1rem",
                      textColor: "#fff",
                      maxWidth: "300px",
                      borderRadius: "10px"
                    }}
                  >
                    Our Blog
                  </Button>
                </a>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <StaticImage
                src="../../images/canva/45.jpeg"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="Laptop displaying blog"
              />
            </Col>
          </Row>
        </Container>

        {/* <Container
          ref={tutorialRef}
          fluid
          style={{
            minHeight: "80vh",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Row
            style={{
              minHeight: "100v",
              paddingTop: `${isMobile ? "0px" : "100px"}`,
            }}
          >
            <Col xs={12} style={{ padding: "50px" }}>
              <h1 style={{ textAlign: "center" }}>Tutorial </h1>
            </Col>

            <Content>
              <div
                css={`
                  position: relative;
                  width: 100%;
                  height: 0;
                  padding-top: 56.25%;
                  padding-bottom: 48px;
                  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
                  margin-top: 1.6em;
                  margin-bottom: 0.9em;
                  overflow: hidden;
                  border-radius: 8px;
                  will-change: transform;
                `}
              >
                <iframe
                  loading="lazy"
                  css={`
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border: none;
                    padding: 0;
                    margin: 0;
                  `}
                  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAEurEF7M1o&#x2F;watch?embed"
                ></iframe>
              </div>
            </Content>
          </Row>
        </Container> */}

        <Container
          ref={profileRef}
          fluid
          style={{
            minHeight: "100%",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Row
            style={{
              paddingTop: `${isMobile ? "10px" : "100px"}`,
            }}
          >
            <Col xs={12} sm={6}>
              <div style={{ color: "#03989e", marginBottom: "30px" }}>
                <h2>COMPANY PROFILE</h2>
                <h2>Tripleaim Software Limited</h2>
              </div>
              <div>
                <h3 style={{ color: "#03989e" }}>Who Are We?</h3>
                <p style={{ textAlign: "justify" }}>
                  We are a software company focusing on improving healthcare
                  businesses through artificial intelligent software solutions.
                </p>
                <p style={{ textAlign: "justify" }}>
                  We are the company behind Patameds and Afyabook online platforms.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Founded in 2021 with a focus on digitalizing healthcare
                  practice. Our team consists of Healthcare providers, Digital
                  health consultants, Content writers, web designers, software
                  developers, and data scientists.
                </p>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div style={{ marginBottom: "30px" }}>
                <h3 style={{ color: "#03989e" }}>Our Vision</h3>
                <p style={{ textAlign: "justify" }}>
                  To contribute to the quality of life by making tools for
                  healthcare
                </p>
              </div>

              <div style={{ marginBottom: "30px" }}>
                <h3 style={{ color: "#03989e" }}>Our Mission</h3>
                <p style={{ textAlign: "justify" }}>
                  To strengthen health systems using intelligent software
                  solutions
                </p>
              </div>

              <div style={{ marginBottom: "30px" }}>
                <h3 style={{ color: "#03989e" }}>Core Values</h3>
                <ul style={{ marginLeft: "10px" }}>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Efficiency and Creativity</li>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Collaboration and Partnerships</li>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Constant improvement</li>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Innovation</li>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Leadership</li>
                  <li><AiOutlineCaretRight style={{ display: "inline" }} /> Learning</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            minHeight: "100%",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Row
            style={{
              minHeight: "100v",
              paddingTop: `${isMobile ? "0px" : "30px"}`,
            }}
          >
            <Col xs={12} sm={6}>
              <h3 style={{ color: "#03989e" }}>Our Services</h3>
              <ul style={{ marginLeft: "10px" }}>
                <li style={{ marginBottom: "10px" }}>
                  <AiOutlineCaretRight style={{ display: "inline" }} /> Getting our clients more customers through digital marketing.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <AiOutlineCaretRight style={{ display: "inline" }} /> Building software that automates day-to-day tasks to save our
                  clients on time, cost and improves productivity.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <AiOutlineCaretRight style={{ display: "inline" }} /> Providing actionable insight from our client’s data through
                  data analytics using statistical software tools.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <AiOutlineCaretRight style={{ display: "inline" }} /> Providing predictions and decision support tools based on our
                  client’s data.
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6}>
              <StaticImage
                src="../../images/canva/38.png"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="unlock your potential"
              />
            </Col>
          </Row>
        </Container>
        <div ref={footerRef}></div>
      </div>
    </React.Fragment>
  )
}

export default Body

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 600px) {
    width: 70%;
  }
`
const SearchHit = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: sticky;
  //top: 60px;

  @media screen and (min-width: 1200px) {
    //top: 15px;
  }
`
