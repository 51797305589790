import React from "react"
import Body from "../components/home/body"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { NavbarContext } from "../context/navbar"

const Home = ({ location }) => {
  const isBrowser = typeof window !== "undefined"

  // const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  return (
    <NavbarContext.Consumer>
      {context => (
        <React.Fragment>
          <Layout>
            <Seo
              title="Patameds - Pharmaceutical B2B marketplace in Kenya"
              description="Easily buy medication online from verified pharmaceutical distributors in Kenya. Discover products at better rates, transact and get them delivered. Learn more"
              keywords="Pharmaceutical companies in kenya,Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain,List of pharmaceutical companies in kenya,Pharmaceutical wholesalers in Nairobi,Pharmaceutical distributors in kenya,Pharmaceutical distributors in Nairobi,companies looking for distributors in kenya,pharmaceutical importers in kenya"
              img="https://www.patameds.com/static/logo-da9b52b652992ee5cb44de5673062d5e.svg"
              siteUrl="https://www.patameds.com"
              canonical="https://www.patameds.com"
            ></Seo>
            <Container>
              <Body
                thankYouModal={context?.thankYouModal}
                openThankYouModal={context?.openThankYouModal}
                closeThankYouModal={context?.closeThankYouModal}
                thankYouVerificationModal={context?.thankYouVerificationModal}
                setThankYouVerificationModal={
                  context?.setThankYouVerificationModal
                }
                navbar={context?.navbar}
                toggle={context?.toggle}
                scrollTo={context?.scrollTo}
                scroll={context?.scroll}
                aboutRef={context?.aboutRef}
                topRef={context?.topRef}
                servicesRef={context?.servicesRef}
                footerRef={context?.footerRef}
                tutorialRef={context?.tutorialRef}
                profileRef={context?.profileRef}
                resourcesRef={context?.resourcesRef}
                blogRef={context?.blogRef}
                isOpenVerifyLoginAlert={context?.isOpenVerifyLoginAlert}
                setIsOpenVerifyLogin={context?.setIsOpenVerifyLogin}
              />
            </Container>
          </Layout>
        </React.Fragment>
      )}
    </NavbarContext.Consumer>
  )
}
export default Home

const Container = styled.div`
  width: 100%;
`
