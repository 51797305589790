import React, { useState } from "react"
import { Row, Form, Button, Alert, Col } from "react-bootstrap"
import Modal from "react-modal"
import api from "../../utils/api"

const modalStyles = {
  content: {
    top: "20%",
    bottom: "auto",
    margin: "auto",
    maxWidth: "700px",
  },
  overlay: {
    zIndex: "5000",
    backgroundColor: "#212529c2",
  },
}

export default function RegistrationModal({
  closeCompleteRegistrationModal,
  isOpenCompleteRegistrationModal,
}) {
  const isBrowser = typeof window !== "undefined"

  const [user] = useState(
    isBrowser ? JSON.parse(window.localStorage.getItem("user")) || {} : {}
  )

  const [profession, setProfession] = useState("Doctor")
  const [registrationNo, setRegistrationNo] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  function close() {
    setError("")
    setLoading(false)
    closeCompleteRegistrationModal()
    setProfession("")
    setRegistrationNo("")
    setLoading(false)
  }

  const register = async () => {
    setLoading(true)
    setError("")
    if (!profession) {
      setLoading(false)
      return setError("Profession is required")
    }

    if (profession != "Med Reps or Sales" && !registrationNo) {
      setLoading(false)
      return setError("Registration number is required.")
    }

    try {
      const userId = user.id

      const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        profession,
        registrationNo,
      }
      const updatedUser = await api().patch(`/users/${userId}`, { ...data })
      localStorage.setItem("user", JSON.stringify(updatedUser.data))
      setLoading(false)
      close()
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <Modal
      isOpen={isOpenCompleteRegistrationModal}
      style={modalStyles}
      ariaHideApp={false}
    >
      <Form
        style={{
          margin: "0px",
          padding: "10px",
          backgroundColor: "#03989e",
        }}
      >
        {error ? <Alert variant="danger">{error}</Alert> : ""}

        <Row>
          <h3 style={{ margin: "auto", padding: "10px" }}>
            Complete Registration
          </h3>
        </Row>

        <Form.Group as={Row} controlId="profession" className="mt-2">
          <Form.Label column sm="2">
            Profession*
          </Form.Label>
          <Col sm="10">
            <Form.Control
              as="select"
              value={profession}
              onChange={e => setProfession(e.target.value)}
            >
              <option>Doctor</option>
              <option>Nurse</option>
              <option>Pharmacist</option>
              <option>Pharmaceutical Technologist</option>
              <option>Clinical Officer</option>
              <option>Med Reps or Sales</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="registrationNo" className="mt-2">
          <Form.Label column sm="2">
            Registration Number
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              placeholder="PPB No. or KMPDB No. or NCK No."
              value={registrationNo}
              onChange={e => setRegistrationNo(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Button disabled={loading} onClick={register}>
          Submit
        </Button>
      </Form>
    </Modal>
  )
}
