import React, { useEffect, useState, useContext } from "react"
import Modal from "react-modal"
import logo from "../../images/patamedslogo.svg"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { Row } from "react-bootstrap"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import api from "../../utils/api"
import { NavbarContext } from "../../context/navbar"

const customStyles = {
  content: {
    top: "25%",
    bottom: "auto",
  },
  overlay: {
    zIndex: "5000",
  },
}

export default function EmailVerificationModal() {
  const isBrowser = typeof window !== "undefined"

  const navContext = useContext(NavbarContext)

  const [token] = useQueryParam("token", StringParam)

  const [modalIsOpen, setIsOpen] = useState(false)
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    if (token) {
      setIsOpen(true)
      verify()
    }
  }, [])

  const verify = async e => {
    try {
      setLoading(true)
      const result = await api().get(`/users/verify_email/${token}`)
      const user = result.data.user
      localStorage.setItem("token", result.data.token)
      localStorage.setItem("user", JSON.stringify(user))
      navContext.setUser(user)
      setLoading(false)
      setSuccess(true)
      navigate("/")
    } catch (error) {
      setLoading(false)
      //console.log(error)
      const message = error.response.data.message
      setLoading(false)
      setError(message)
      navigate("/")
    }
  }

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <Container>
        <HeroText>
          <LinkLogo>
            <img src={logo} alt="logo" />
          </LinkLogo>
          <Row>
            {loading && (
              <div>
                <h3>Verifying</h3>
                <p>Your account is being verified.</p>
              </div>
            )}
            {success && (
              <div>
                <h3>Thank You</h3>
                <p>Your account has been verified.</p>
              </div>
            )}
            {error && (
              <div>
                <h3>Error</h3>
                <p>Verification failed please try again.</p>
              </div>
            )}
          </Row>
        </HeroText>
        {!loading && <Button onClick={() => closeModal()}>Close</Button>}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    #1c333a 10%,
    #1b353c 50%,
    #1d3238 70%,
    #187e9c 100%
  );
  //height: 50vh;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  z-index: 4000;
  margin: -1.1rem -1.1rem;
`
const HeroText = styled.div`
  color: #fff;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
`
const LinkLogo = styled.div`
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;

  @media screen and (min-width: 600px) {
    width: 40%;
  }
  @media screen and (min-width: 800px) {
    width: 30%;
  }
  @media screen and (min-width: 1100px) {
    width: 20%;
  }
  @media screen and (min-width: 1500px) {
    width: 10%;
  }
`
const Button = styled.div`
  cursor: pointer;
  background: #ff5722;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  color: #fff;
  margin-bottom: 1rem;

  :hover {
    background: #f44336;
  }

  @media screen and (min-width: 600px) {
    padding: 1rem 5rem;
  }
`
